body {
	margin: 0;
	padding: 0;
}

.web3modal-provider-name {
	text-transform: capitalize;
	color: #21458e;
	font-family: 'Fredoka One';
}

.web3modal-provider-description {
	font-family: 'Fredoka One';
}
